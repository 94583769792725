import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const userTypes = Object.values(LISTINGS.USER.TYPE);
const documentTypes = ['AML', 'KYC', 'CDD', 'Transactions'];

const mockUserArchiveItem = () => {
  const documentType = faker.helpers.arrayElement(documentTypes);
  const type = faker.helpers.arrayElement(userTypes);
  const name =
    type === LISTINGS.USER.TYPE.INDIVIDUAL
      ? faker.person.fullName()
      : faker.company.name();
  const item = {
    archived_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    docs: `${documentType} Documents`,
    document_type: documentType,
    id: faker.string.uuid(),
    name,
    tx_hash: faker.string.alphanumeric(64),
    type,
  };
  return item;
};

const mockUserArchiveItems = ({ items = 3 } = {}) => {
  return faker.helpers.multiple(mockUserArchiveItem, {
    count: items,
  });
};

const mockUserArchiveSummary = () => {
  const item = {
    all: faker.number.int({ max: 9000, min: 1000 }),
    tags: faker.number.int({ max: 9, min: 1 }),
  };
  return item;
};

export { mockUserArchiveItem, mockUserArchiveItems, mockUserArchiveSummary };
