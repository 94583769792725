import { mockVCItems, mockVCSummary } from '@/__mocks__';

const vcApi = {
  requestList() {
    return new Promise((resolve) => {
      const items = 10;
      const response = {
        data: {
          credentials: mockVCItems(items),
          paging: {
            next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            next_link:
              'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            total_count: items,
          },
        },
      };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
  requestSummary() {
    return new Promise((resolve) => {
      const response = { data: mockVCSummary() };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
};

export default vcApi;
