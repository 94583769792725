import { vcApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initVc({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchSummary'), dispatch('fetchCredentials')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await vcApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchCredentials({ commit }) {
    try {
      const response = await vcApi.requestList();
      commit('addCredentials', response.data.credentials);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async incrementCredentials({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchCredentials');
    commit('setAdding', false);
  },
};
