import { faker } from '@faker-js/faker';

const mockUserDBItem = () => {
  const item = {
    address:
      faker.location.streetAddress({ useFullAddress: true }) +
      ' - ' +
      faker.location.city(),
    cellphone: faker.phone.number(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    email: faker.internet.email(),
    id: faker.string.uuid(),
    job: faker.person.jobTitle(),
    name: faker.person.fullName(),
    phone: faker.phone.number(),
  };
  return item;
};

const mockUserDBItems = (items = 3) => {
  return faker.helpers.multiple(mockUserDBItem, {
    count: items,
  });
};

const mockUserDBSummary = () => {
  const item = {
    all: faker.number.int({ max: 5000, min: 1000 }),
    export: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

export { mockUserDBItem, mockUserDBItems, mockUserDBSummary };
