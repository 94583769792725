import { userArchiveApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initArchive({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchSummary'), dispatch('fetchDocs')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await userArchiveApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchDocs({ commit }) {
    try {
      const response = await userArchiveApi.requestList();
      commit('addDocs', response.data.docs);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async incrementDocs({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchDocs');
    commit('setAdding', false);
  },
};
