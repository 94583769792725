import { mockTxsAlarmItem } from '@/__mocks__';

const txsAlarmApi = {
  requestDetail(isOpen) {
    return new Promise((resolve) => {
      const response = { data: mockTxsAlarmItem(isOpen) };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
};

export default txsAlarmApi;
