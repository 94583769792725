import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const userStatuses = Object.values(LISTINGS.USER.STATUS);
const userTypes = Object.values(LISTINGS.USER.TYPE);

const mockUserRegistryItem = (userStatus) => {
  const status = userStatus || faker.helpers.arrayElement(userStatuses);
  const type = faker.helpers.arrayElement(userTypes);
  const name =
    type === LISTINGS.USER.TYPE.INDIVIDUAL
      ? faker.person.fullName()
      : faker.company.name();
  const item = {
    address:
      faker.location.streetAddress({ useFullAddress: true }) +
      ' - ' +
      faker.location.city(),
    cellphone: faker.phone.number(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    email: faker.internet.email(),
    id: faker.string.uuid(),
    name,
    phone: faker.phone.number(),
    status,
    type,
  };
  return item;
};

const mockUserRegistryItems = ({ items = 3, status = '' } = {}) => {
  return faker.helpers.multiple(() => mockUserRegistryItem(status), {
    count: items,
  });
};

const mockUserRegistrySummary = () => {
  const item = {
    approved: faker.number.int({ max: 5000, min: 1000 }),
    done: faker.number.int({ max: 5, min: 1 }),
    rejected: faker.number.int({ max: 500, min: 100 }),
    today: faker.number.int({ max: 50, min: 1 }),
    todo: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

export { mockUserRegistryItem, mockUserRegistryItems, mockUserRegistrySummary };
