import {
  mockQuarterItem,
  mockQuarterItems,
  mockQuarterSummary,
} from '@/__mocks__';

const quarterApi = {
  requestList(status) {
    return new Promise((resolve) => {
      const items = 10;
      const response = {
        data: {
          quarters: mockQuarterItems({ items, status }),
          paging: {
            next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            next_link:
              'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            total_count: items,
          },
        },
      };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
  requestDetail(status) {
    return new Promise((resolve) => {
      const response = {
        data: mockQuarterItem(status),
      };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
  requestSummary() {
    return new Promise((resolve) => {
      const response = { data: mockQuarterSummary() };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
};

export default quarterApi;
