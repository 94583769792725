<template>
  <v-card outlined :loading="loading">
    <v-card-title
      class="d-flex justify-center text-center text-subtitle-1 font-weight-bold"
    >
      <span v-html="title" />
    </v-card-title>
    <v-spacer />
    <v-card-text class="d-flex justify-center card-text">
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseHeaderBox',
  props: {
    title: {
      type: String,
      default: '',
      note: 'Box title',
    },
    loading: {
      type: Boolean,
      default: false,
      note: 'The loading state',
    },
  },
};
</script>

<style scoped>
.card-text {
  overflow-y: auto;
  height: 110px;
}
</style>
