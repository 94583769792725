import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseDrawerMenuItem from '@/components/BaseDrawerMenuItem.vue';
import BaseFileList from '@/components/BaseFileList.vue';
import BaseFormDragArea from '@/components/BaseFormDragArea.vue';
import BaseFormSelect from '@/components/BaseFormSelect.vue';
import BaseFormTextArea from '@/components/BaseFormTextArea.vue';
import BaseFormTextField from '@/components/BaseFormTextField.vue';
import BaseHeaderBox from '@/components/BaseHeaderBox.vue';
import BaseListItem from '@/components/BaseListItem.vue';
import BaseSmallWindow from '@/components/BaseSmallWindow.vue';
import BaseVerifyButton from '@/components/BaseVerifyButton.vue';
import TheDashboardHeader from '@/components/TheDashboardHeader.vue';
import TheSearchBar from '@/components/search-bar/TheSearchBar.vue';

Vue.component('base-drawer-menu-item', BaseDrawerMenuItem);
Vue.component('base-file-list', BaseFileList);
Vue.component('base-form-drag-area', BaseFormDragArea);
Vue.component('base-form-select', BaseFormSelect);
Vue.component('base-form-text-area', BaseFormTextArea);
Vue.component('base-form-text-field', BaseFormTextField);
Vue.component('base-header-box', BaseHeaderBox);
Vue.component('base-list-item', BaseListItem);
Vue.component('base-small-window', BaseSmallWindow);
Vue.component('base-verify-button', BaseVerifyButton);
Vue.component('the-dashboard-header', TheDashboardHeader);
Vue.component('the-search-bar', TheSearchBar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
