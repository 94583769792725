import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const userRisks = Object.values(LISTINGS.USER.RISK);

const mockUserRiskItem = () => {
  const risk = faker.helpers.arrayElement(userRisks);
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    id: faker.string.uuid(),
    issued_on: faker.date.recent({ refDate: '2024-03-01T12:00:00.000Z' }),
    name: faker.person.fullName(),
    risk,
    tx_hash: faker.string.alphanumeric(64),
  };
  return item;
};

const mockUserRiskItems = (items = 3) => {
  return faker.helpers.multiple(mockUserRiskItem, {
    count: items,
  });
};

const mockUserRiskSummary = () => {
  const item = {
    high: faker.number.int({ max: 5, min: 1 }),
    low: faker.number.int({ max: 5000, min: 1000 }),
    medium: faker.number.int({ max: 50, min: 1 }),
    rejected: faker.number.int({ max: 50, min: 1 }),
    unassigned: faker.number.int({ max: 5, min: 1 }),
  };
  return item;
};

export { mockUserRiskItem, mockUserRiskItems, mockUserRiskSummary };
