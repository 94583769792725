import Vue from 'vue';
import Vuex from 'vuex';

import archive from './archive';
import auth from './auth';
import certifiedTraining from './certified-training';
import dbExport from './db-export';
import operatorLogs from './operator-logs';
import preScreening from './pre-screening';
import riskAssessmentAssign from './risk-assessment/assign';
import riskAssessmentDashboard from './risk-assessment/dashboard';
import sar from './sar';
import txsMonitor from './txs-monitor';
import txsScenario from './txs-scenario';
import vasp from './vasp';
import vc from './vc';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    archive,
    auth,
    certifiedTraining,
    dbExport,
    operatorLogs,
    preScreening,
    riskAssessmentAssign,
    riskAssessmentDashboard,
    sar,
    txsMonitor,
    txsScenario,
    vasp,
    vc,
  },
});
