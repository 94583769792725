import { userOperatorApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initOperatorLogs({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchSummary'), dispatch('fetchLogs')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await userOperatorApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchLogs({ commit }) {
    try {
      const response = await userOperatorApi.requestList();
      commit('addLogs', response.data.logs);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async incrementLogs({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchLogs');
    commit('setAdding', false);
  },
};
