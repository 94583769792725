import { courseApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initCertifiedTrainingDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchCourses'), dispatch('fetchSummary')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await courseApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchCourses({ commit }) {
    try {
      const response = await courseApi.requestList();
      commit('addCourses', response.data.courses);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async incrementCourses({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchCourses');
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} getters
   * @param {String} id
   */
  initCourseDetail({ commit, getters }, id) {
    commit('setDetail', null);
    const courses = getters['list'];
    const course = courses.find((course) => course.id === id);
    if (course) {
      commit('setDetail', course);
    }
  },
  /**
   *
   * @param {Object} getters
   * @param {String} id
   */
  getLesson({ getters }, id) {
    const course = getters['detail'];
    if (!course || !course.lessons || !course.lessons.length) {
      return null;
    }
    const lesson = course.lessons.find((lesson) => lesson.id === id);
    return lesson;
  },
};
