import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const userStatuses = Object.values(LISTINGS.USER.LICENSE);
const userTypes = Object.values(LISTINGS.OPERATOR.TYPE);

const mockUserOperatorItem = (userStatus) => {
  const status = userStatus || faker.helpers.arrayElement(userStatuses);
  const type = faker.helpers.arrayElement(userTypes);
  const name = faker.person.fullName();
  const item = {
    archived_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    id: faker.string.uuid(),
    name,
    status,
    tx_hash: faker.string.alphanumeric(64),
    type,
  };
  return item;
};

const mockUserOperatorItems = ({ items = 3, status = '' } = {}) => {
  return faker.helpers.multiple(() => mockUserOperatorItem(status), {
    count: items,
  });
};

const mockUserOperatorSummary = () => {
  const item = {
    active: faker.number.int({ max: 9, min: 1 }),
    expired: faker.number.int({ max: 9, min: 1 }),
  };
  return item;
};

export { mockUserOperatorItem, mockUserOperatorItems, mockUserOperatorSummary };
