import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const riskLevels = Object.values(LISTINGS.LEVEL.RISK);

const mockRiskIndicatorItem = (riskLevel) => {
  const level = riskLevel || faker.helpers.arrayElement(riskLevels);
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    description: faker.lorem.sentence({ min: 10, max: 30 }),
    id: faker.string.uuid(),
    level,
    name: faker.lorem.sentence({ min: 2, max: 3 }),
  };
  return item;
};

const mockRiskIndicatorItems = ({ items = 3, level = '' } = {}) => {
  return faker.helpers.multiple(() => mockRiskIndicatorItem(level), {
    count: items,
  });
};

export { mockRiskIndicatorItem, mockRiskIndicatorItems };
