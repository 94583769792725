import { mockUserArchiveItems, mockUserArchiveSummary } from '@/__mocks__';

const userArchiveApi = {
  requestList() {
    return new Promise((resolve) => {
      const items = 20;
      const response = {
        data: {
          docs: mockUserArchiveItems({ items }),
          paging: {
            next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            next_link:
              'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            total_count: items,
          },
        },
      };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
  requestSummary() {
    return new Promise((resolve) => {
      const response = { data: mockUserArchiveSummary() };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
};

export default userArchiveApi;
