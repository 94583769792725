import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const operatorTypes = Object.values(LISTINGS.OPERATOR.TYPE);

const mockTxsAlarmItem = (isOpen) => {
  const note = 'Usual annual operation for office rent payment';
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    evaluation_date: faker.date.recent({ refDate: '2024-03-01T12:00:00.000Z' }),
    id: faker.string.uuid(),
    note: !isOpen ? note : '',
    operation_date: faker.date.recent({ refDate: '2024-02-01T12:00:00.000Z' }),
    operator: faker.person.fullName(),
    operator_type: faker.helpers.arrayElement(operatorTypes),
    rule: '>150.000 Euro ,Blacklisted country (Iran) ,1800% monthly usual transaction',
    score: '+60',
    type: 'ANTI FRAUD SCENARIO',
    user: faker.person.fullName(),
  };
  return item;
};

const mockTxsAlarmItems = (items = 3) => {
  return faker.helpers.multiple(mockTxsAlarmItem, {
    count: items,
  });
};

export { mockTxsAlarmItem, mockTxsAlarmItems };
