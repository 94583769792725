const KEYCLOAK = Object.freeze({
  CLIENT_ID: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  REALM: process.env.VUE_APP_KEYCLOAK_REALM,
  URL: process.env.VUE_APP_KEYCLOAK_URL,
  ON_LOAD: {
    CHECK_SSO: 'check-sso',
    LOGIN: 'login-required',
  },
});

const USER_ROLE = Object.freeze({
  ANY: 'any',
  AUTHORIZED: 'authorized',
  NOT_AUTHORIZED: 'notAuthorized',
});

export default Object.freeze({
  KEYCLOAK,
  REFRESH_TOKEN_TIME: 60,
  USER_ROLE,
});
