<template>
  <div>
    <div class="text-subtitle-2 info--text">Attached Files</div>
    <v-card class="mb-3" color="blue lighten-5" outlined>
      <v-card-text>
        <v-list color="transparent" dense>
          <v-list-item v-for="(file, i) in files" :key="i">
            <v-list-item-content>
              <v-list-item-title>
                <span v-text="file" />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="red" @click="remove(file)">
                {{ mdiCloseCircle }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiCloseCircle } from '@mdi/js';

export default {
  name: 'BaseFileList',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiCloseCircle,
    };
  },
  emits: ['remove'],
  methods: {
    remove(file) {
      this.$emit('remove', file);
    },
  },
};
</script>
