import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const ScenarioManageDecisionView = loadView(
  'txs-monitor/manage/ScenarioManageDecisionView',
);
const ScenarioManageRulesView = loadView(
  'txs-monitor/manage/ScenarioManageRulesView',
);
const ScenarioManageTriggerView = loadView(
  'txs-monitor/manage/ScenarioManageTriggerView',
);
const TxsMonitorDashboardView = loadView('txs-monitor/TxsMonitorDashboardView');
const TxsMonitorDetailView = loadView('txs-monitor/TxsMonitorDetailView');
const TxsMonitorScenarioRouter = loadView(
  'txs-monitor/TxsMonitorScenarioRouter',
);
const TxsMonitorScenarioDashboardView = loadView(
  'txs-monitor/TxsMonitorScenarioDashboardView',
);
const TxsMonitorScenarioManageRouter = loadView(
  'txs-monitor/TxsMonitorScenarioManageRouter',
);

const txsMonitorRoute = {
  path: ROUTE.PATH.TXS_MONITOR,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.TXS_MONITOR,
      component: TxsMonitorDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.TXS_MONITOR_DASHBOARD,
      name: ROUTE.NAME.TXS_MONITOR_DASHBOARD,
      component: TxsMonitorDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.TXS_MONITOR_DETAIL,
      name: ROUTE.NAME.TXS_MONITOR_DETAIL,
      component: TxsMonitorDetailView,
      props: (route) => ({
        id: route.params.id,
        isOpen: route.query.is_open,
      }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.TXS_MONITOR_SCENARIO,
      component: TxsMonitorScenarioRouter,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.TXS_MONITOR_SCENARIO,
          component: TxsMonitorScenarioDashboardView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TXS_MONITOR_SCENARIO_DASHBOARD,
          name: ROUTE.NAME.TXS_MONITOR_SCENARIO_DASHBOARD,
          component: TxsMonitorScenarioDashboardView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TXS_MONITOR_SCENARIO_MANAGE,
          component: TxsMonitorScenarioManageRouter,
          props: true,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
          children: [
            {
              path: '',
              name: ROUTE.NAME.TXS_MONITOR_SCENARIO_MANAGE,
              component: ScenarioManageTriggerView,
              props: true,
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
              },
            },
            {
              path: ROUTE.PATH.TXS_MONITOR_SCENARIO_MANAGE_DECISION,
              name: ROUTE.NAME.TXS_MONITOR_SCENARIO_MANAGE_DECISION,
              component: ScenarioManageDecisionView,
              props: true,
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
              },
            },
            {
              path: ROUTE.PATH.TXS_MONITOR_SCENARIO_MANAGE_RULES,
              name: ROUTE.NAME.TXS_MONITOR_SCENARIO_MANAGE_RULES,
              component: ScenarioManageRulesView,
              props: true,
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
              },
            },
            {
              path: ROUTE.PATH.TXS_MONITOR_SCENARIO_MANAGE_TRIGGER,
              name: ROUTE.NAME.TXS_MONITOR_SCENARIO_MANAGE_TRIGGER,
              component: ScenarioManageTriggerView,
              props: true,
              meta: {
                auth: AUTH.USER_ROLE.AUTHORIZED,
              },
            },
          ],
        },
      ],
    },
  ],
};

export default txsMonitorRoute;
