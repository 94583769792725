import { quarterApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} status
   */
  async initVaspDashboard({ commit, dispatch }, status = '') {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchQuarters', status),
      dispatch('fetchSummary'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await quarterApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} status
   */
  async fetchQuarters({ commit }, status = '') {
    try {
      const response = await quarterApi.requestList(status);
      commit('addQuarters', response.data.quarters);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} status
   */
  async incrementQuarters({ commit, dispatch }, status = '') {
    commit('setAdding', true);
    await dispatch('fetchQuarters', status);
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} getters
   * @param {String} id
   */
  initVaspDetail({ commit, getters }, id) {
    commit('setDetail', null);
    const quarters = getters['list'];
    const quarter = quarters.find((quarter) => quarter.id === id);
    if (quarter) {
      commit('setDetail', quarter);
    }
  },
  // /**
  //  *
  //  * @param {Function} commit
  //  * @param {Function} dispatch
  //  * @param {String} id
  //  */
  // async initVaspDetail({ commit, dispatch }, id) {
  //   commit('reset');
  //   commit('setLoading', true);
  //   const requests = [dispatch('fetchQuarter', id)];
  //   await Promise.all(requests);
  //   commit('setLoading', false);
  // },
  /**
   *
   * @param {Function} commit
   * @param {String} id
   */
  async fetchQuarter({ commit }, id) {
    try {
      const response = await quarterApi.requestDetail(id);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
