<template>
  <v-textarea
    auto-grow
    class="text-caption"
    clearable
    dense
    outlined
    :clear-icon="mdiCloseCircle"
    :disabled="disabled"
    v-model="message"
  >
    <template #label>
      <span class="info--text" v-text="label" />
    </template>
  </v-textarea>
</template>

<script>
import { mdiCloseCircle } from '@mdi/js';

export default {
  name: 'BaseFormTextArea',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiCloseCircle,
    };
  },
  emits: ['input'],
  computed: {
    message: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
