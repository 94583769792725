<template>
  <v-row>
    <v-col cols="12" md="8" offset-md="2">
      <v-card class="px-1 px-md-5" outlined>
        <v-toolbar flat>
          <v-spacer />
          <v-toolbar-title>
            <slot name="title" />
          </v-toolbar-title>
          <v-spacer />
          <v-btn color="transparent" icon @click="$router.back()">
            <v-icon color="grey darken-3">{{ mdiCloseThick }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot name="text" />
        </v-card-text>
        <v-card-actions>
          <slot name="actions" />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCloseThick } from '@mdi/js';

export default {
  name: 'BaseSmallWindow',
  data() {
    return {
      mdiCloseThick,
    };
  },
};
</script>
