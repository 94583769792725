<template>
  <v-btn
    color="info"
    :depressed="isVerified"
    :disabled="isVerified"
    :outlined="!isVerified"
    @click="isVerified = true"
  >
    <div class="px-5" v-text="label" />
  </v-btn>
</template>

<script>
export default {
  name: 'BaseVerifyButton',
  data() {
    return {
      isVerified: false,
    };
  },
  computed: {
    label() {
      return this.isVerified ? 'Verified' : 'Verify';
    },
  },
};
</script>
