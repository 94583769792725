import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const quarters = Object.values(LISTINGS.QUARTERS);
const statuses = Object.values(LISTINGS.STATUS);

const mockQuarterItem = (quarterStatus = '') => {
  const quarter = faker.helpers.arrayElement(quarters);
  const name = LISTINGS.LABELS[quarter];
  const past = faker.date.past({
    years: 3,
    refDate: '2024-02-01T12:00:00.000Z',
  });
  const soon = faker.date.soon({
    refDate: '2024-03-01T12:00:00.000Z',
  });
  const future = faker.date.soon({
    refDate: '2024-04-01T12:00:00.000Z',
  });
  const status = quarterStatus || faker.helpers.arrayElement(statuses);
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    dateSubmission: faker.date.between({ from: past, to: soon }),
    dateStarting: soon,
    dateEnding: future,
    id: faker.string.uuid(),
    name,
    notes: faker.lorem.sentence(),
    operator: faker.person.fullName(),
    quarter,
    receipts: [faker.system.fileName()],
    status,
    submission: faker.date.between({ from: past, to: soon }),
    year: past.getFullYear(),
  };
  return item;
};

const mockQuarterItems = ({ items = 3, status = '' } = {}) => {
  return faker.helpers.multiple(() => mockQuarterItem(status), {
    count: items,
  });
};

const mockQuarterSummary = () => {
  const item = {
    deadline: faker.date.soon({ refDate: '2024-04-01T00:00:00.000Z' }),
    submitted: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

export { mockQuarterItem, mockQuarterItems, mockQuarterSummary };
