import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const PreScreeningDashboardView = loadView(
  'pre-screening/PreScreeningDashboardView',
);
const PreScreeningVerifyKybView = loadView(
  'pre-screening/PreScreeningVerifyKybView',
);
const VerifyKybCDDView = loadView('pre-screening/verify-kyb/VerifyKybCDDView');
const VerifyKybDilisenseView = loadView(
  'pre-screening/verify-kyb/VerifyKybDilisenseView',
);
const VerifyKybDocsView = loadView(
  'pre-screening/verify-kyb/VerifyKybDocsView',
);
const VerifyKybFacecheckView = loadView(
  'pre-screening/verify-kyb/VerifyKybFacecheckView',
);
const VerifyKybSummaryView = loadView(
  'pre-screening/verify-kyb/VerifyKybSummaryView',
);
const PreScreeningVerifyKycView = loadView(
  'pre-screening/PreScreeningVerifyKycView',
);
const VerifyKycCDDView = loadView('pre-screening/verify-kyc/VerifyKycCDDView');
const VerifyKycDilisenseView = loadView(
  'pre-screening/verify-kyc/VerifyKycDilisenseView',
);
const VerifyKycDocsView = loadView(
  'pre-screening/verify-kyc/VerifyKycDocsView',
);
const VerifyKycFacecheckView = loadView(
  'pre-screening/verify-kyc/VerifyKycFacecheckView',
);
const VerifyKycSummaryView = loadView(
  'pre-screening/verify-kyc/VerifyKycSummaryView',
);

const preScreeningRoute = {
  path: ROUTE.PATH.PRE_SCREENING,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.PRE_SCREENING,
      component: PreScreeningDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.PRE_SCREENING_DASHBOARD,
      name: ROUTE.NAME.PRE_SCREENING_DASHBOARD,
      component: PreScreeningDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYB,
      component: PreScreeningVerifyKybView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYB_CDD,
          component: VerifyKybCDDView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYB_CDD,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYB_CDD,
          component: VerifyKybCDDView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYB_DILISENSE,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYB_DILISENSE,
          component: VerifyKybDilisenseView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYB_DOCS,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYB_DOCS,
          component: VerifyKybDocsView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYB_FACECHECK,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYB_FACECHECK,
          component: VerifyKybFacecheckView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYB_SUMMARY,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYB_SUMMARY,
          component: VerifyKybSummaryView,
          props: true,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
      ],
    },
    {
      path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYC,
      component: PreScreeningVerifyKycView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYC_CDD,
          component: VerifyKycCDDView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYC_CDD,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYC_CDD,
          component: VerifyKycCDDView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYC_DILISENSE,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYC_DILISENSE,
          component: VerifyKycDilisenseView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYC_DOCS,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYC_DOCS,
          component: VerifyKycDocsView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYC_FACECHECK,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYC_FACECHECK,
          component: VerifyKycFacecheckView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.PRE_SCREENING_VERIFY_KYC_SUMMARY,
          name: ROUTE.NAME.PRE_SCREENING_VERIFY_KYC_SUMMARY,
          component: VerifyKycSummaryView,
          props: true,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
      ],
    },
  ],
};

export default preScreeningRoute;
