import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const RiskAssessmentDashboardView = loadView(
  'risk-assessment/RiskAssessmentDashboardView',
);
const RiskAssessmentAssignView = loadView(
  'risk-assessment/RiskAssessmentAssignView',
);
const RiskAssessmentBasicView = loadView(
  'risk-assessment/RiskAssessmentBasicView',
);
const RiskAssessmentEnhancedView = loadView(
  'risk-assessment/RiskAssessmentEnhancedView',
);

const riskAssessmentRoute = {
  path: ROUTE.PATH.RISK_ASSESSMENT,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.RISK_ASSESSMENT,
      component: RiskAssessmentDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.RISK_ASSESSMENT_ASSIGN,
      name: ROUTE.NAME.RISK_ASSESSMENT_ASSIGN,
      component: RiskAssessmentAssignView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.RISK_ASSESSMENT_BASIC,
      name: ROUTE.NAME.RISK_ASSESSMENT_BASIC,
      component: RiskAssessmentBasicView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.RISK_ASSESSMENT_DASHBOARD,
      name: ROUTE.NAME.RISK_ASSESSMENT_DASHBOARD,
      component: RiskAssessmentDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.RISK_ASSESSMENT_ENHANCED,
      name: ROUTE.NAME.RISK_ASSESSMENT_ENHANCED,
      component: RiskAssessmentEnhancedView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default riskAssessmentRoute;
