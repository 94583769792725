import { faker } from '@faker-js/faker';

const mockVCItem = () => {
  const item = {
    address:
      faker.location.streetAddress({ useFullAddress: true }) +
      ' - ' +
      faker.location.city(),
    cellphone: faker.phone.number(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    email: faker.internet.email(),
    id: faker.string.uuid(),
    job: faker.person.jobTitle(),
    name: faker.person.fullName(),
    phone: faker.phone.number(),
  };
  return item;
};

const mockVCItems = (items = 3) => {
  return faker.helpers.multiple(mockVCItem, {
    count: items,
  });
};

const mockVCSummary = () => {
  const item = {
    all: faker.number.int({ max: 5000, min: 1000 }),
    done: faker.number.int({ max: 10, min: 1 }),
    revocked: faker.number.int({ max: 1000, min: 100 }),
    today: faker.number.int({ max: 10, min: 1 }),
    todo: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

export { mockVCItem, mockVCItems, mockVCSummary };
