import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const VaspDashboardView = loadView('vasp/VaspDashboardView');
const VaspDetailView = loadView('vasp/VaspDetailView');
const VaspManageView = loadView('vasp/VaspManageView');
const VaspNewView = loadView('vasp/VaspNewView');

const vaspRoute = {
  path: ROUTE.PATH.VASP,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.VASP,
      component: VaspDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.VASP_DASHBOARD,
      name: ROUTE.NAME.VASP_DASHBOARD,
      component: VaspDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.VASP_DETAIL,
      name: ROUTE.NAME.VASP_DETAIL,
      component: VaspDetailView,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.VASP_MANAGE,
      name: ROUTE.NAME.VASP_MANAGE,
      component: VaspManageView,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.VASP_NEW,
      name: ROUTE.NAME.VASP_NEW,
      component: VaspNewView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default vaspRoute;
