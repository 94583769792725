<template>
  <div>
    <div class="text-subtitle-2 info--text" v-text="label" />
    <v-card
      id="drag-area"
      class="my-3 drag-area"
      :class="{ 'grey lighten-1': isDragover }"
      flat
      @drop.prevent="onDrop"
      @dragover.prevent="isDragover = true"
      @dragenter.prevent="isDragover = true"
      @dragleave.prevent="isDragover = false"
    >
      <v-card-text>
        <v-row
          class="text-center drag-area_info d-flex flex-column justify-center align-center"
          dense
        >
          <v-icon class="mt-5" color="info" size="60">
            {{ mdiCloudUpload }}
          </v-icon>
          <div class="info--text font-weight-bold" v-text="info" />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-file-input
            hide-input
            :prepend-icon="mdiUpload"
            v-model="file"
          ></v-file-input>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiCloudUpload, mdiUpload } from '@mdi/js';

export default {
  name: 'BaseFormDragArea',
  props: {
    value: {
      type: File,
      default: null,
      note: 'The file to be uploaded.',
    },
    info: {
      type: String,
      default: '',
      note: 'The info message to be displayed in the drag area.',
    },
    label: {
      type: String,
      default: '',
      note: 'The label of the drag area.',
    },
  },
  data() {
    return {
      mdiCloudUpload,
      mdiUpload,
      isDragover: false,
    };
  },
  emits: ['input'],
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onDrop(event) {
      if (event.dataTransfer.files.length > 1) {
        this.isDragover = false;
        return;
      }
      this.file = event.dataTransfer.files[0];
      this.isDragover = false;
    },
  },
};
</script>

<style scoped>
.drag-area {
  border: 3px dashed #2196f3;
}
.drag-area_info {
  white-space: pre-line;
}
</style>
