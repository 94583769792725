import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const mockTxsScenarioItem = ({ name, description, risk }) => {
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    description,
    id: faker.string.uuid(),
    name,
    risk,
    tx_hash: faker.string.alphanumeric(64),
  };
  return item;
};

const mockTxsScenarioItems = () => {
  const items = [
    mockTxsScenarioItem({
      name: 'ANTI FRAUD SCENARIO',
      description: 'Real Time',
      risk: LISTINGS.SCENARIO.RISK.REALTIME,
    }),
    mockTxsScenarioItem({
      name: 'MEDIUM RISK USER SCENARIO',
      description: 'Weekly',
      risk: LISTINGS.SCENARIO.RISK.MEDIUM,
    }),
    mockTxsScenarioItem({
      name: 'LOW RISK USER SCENARIO',
      description: 'Monthly',
      risk: LISTINGS.SCENARIO.RISK.LOW,
    }),
    mockTxsScenarioItem({
      name: 'HIGH RISK USER SCENARIO',
      description: 'Daily',
      risk: LISTINGS.SCENARIO.RISK.HIGH,
    }),
  ];
  return items;
};

const mockTxsScenarioSummary = () => {
  const item = {
    daily: faker.number.int({ max: 10, min: 1 }),
    monthly: faker.number.int({ max: 10, min: 1 }),
    realtime: faker.number.int({ max: 10, min: 1 }),
    weekly: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

export { mockTxsScenarioSummary, mockTxsScenarioItem, mockTxsScenarioItems };
