<template>
  <v-text-field dense outlined :disabled="disabled" v-model="textValue">
    <template #label>
      <span class="info--text" v-text="label" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseFormTextField',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  computed: {
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
