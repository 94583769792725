import { render, staticRenderFns } from "./BaseSmallWindow.vue?vue&type=template&id=da6237a0"
import script from "./BaseSmallWindow.vue?vue&type=script&lang=js"
export * from "./BaseSmallWindow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports