import { txsAlarmApi, txsMonitorApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initTxsMonitor({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchSummary'), dispatch('fetchTxs')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await txsMonitorApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchTxs({ commit }) {
    try {
      const response = await txsMonitorApi.requestList();
      commit('addTxs', response.data.txs);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async incrementTxs({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchTxs');
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Boolean} isOpen
   */
  async initTxsMonitorDetail({ commit, dispatch }, isOpen) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchDetail', isOpen)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Boolean} isOpen
   */
  async fetchDetail({ commit }, isOpen) {
    try {
      const response = await txsAlarmApi.requestDetail(isOpen);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
