import {
  mdiCheck,
  mdiClipboardEditOutline,
  mdiGauge,
  mdiGaugeEmpty,
  mdiGaugeFull,
  mdiRotateRight,
  mdiSortAscending,
  mdiSortDescending,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiViewGridOutline,
  mdiViewListOutline,
} from '@mdi/js';

const LESSON = Object.freeze({
  TYPE: {
    AUDIO: 'audio',
    QUIZ: 'quiz',
    VIDEO: 'video',
  },
});

const LEVEL = Object.freeze({
  RISK: {
    HIGH: 'high',
    MEDIUM: 'medium',
    LOW: 'low',
  },
});

const OPERATOR = Object.freeze({
  TYPE: {
    AML_OPERATOR: 'AML Operator',
    AMRO_MANAGER: 'AMRO Manager',
    SAR_MANAGER: 'SAR Manager',
  },
});

const POSITIONINGS = Object.freeze({
  GRID: 'grid',
  LIST: 'list',
});

const QUARTERS = Object.freeze({
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
});

const SCENARIO = Object.freeze({
  RISK: {
    HIGH: 'high',
    MEDIUM: 'medium',
    LOW: 'low',
    REALTIME: 'realtime',
  },
});

const SORTINGS = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

const STATUS = Object.freeze({
  DONE: 'Done',
  PENDING: 'Pending',
});

const USER = Object.freeze({
  LICENSE: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  RISK: {
    HIGH: 'high',
    LOW: 'low',
    MODERATED: 'moderated',
    SEVERE: 'severe',
    UNCLASSIFIED: 'unclassified',
  },
  STATUS: {
    NOT_VERIFIED: 'not_verified',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
  },
  TYPE: {
    CORPORATE: 'corporate',
    INDIVIDUAL: 'individual',
  },
});

const ICONS = Object.freeze({
  [LEVEL.RISK.HIGH]: mdiGaugeFull,
  [LEVEL.RISK.MEDIUM]: mdiGauge,
  [LEVEL.RISK.LOW]: mdiGaugeEmpty,
  [POSITIONINGS.GRID]: mdiViewGridOutline,
  [POSITIONINGS.LIST]: mdiViewListOutline,
  [SORTINGS.ASC]: mdiSortAscending,
  [SORTINGS.DESC]: mdiSortDescending,
  [STATUS.DONE]: mdiCheck,
  [STATUS.PENDING]: mdiRotateRight,
  [USER.STATUS.NOT_VERIFIED]: mdiClipboardEditOutline,
  [USER.STATUS.RESOLVED]: mdiThumbUpOutline,
  [USER.STATUS.REJECTED]: mdiThumbDownOutline,
});

const LABELS = Object.freeze({
  [LEVEL.RISK.HIGH]: 'High',
  [LEVEL.RISK.MEDIUM]: 'Medium',
  [LEVEL.RISK.LOW]: 'Low',
  [POSITIONINGS.GRID]: 'Grid',
  [POSITIONINGS.LIST]: 'List',
  [QUARTERS.FIRST]: 'Primo trimestre',
  [QUARTERS.SECOND]: 'Secondo trimestre',
  [QUARTERS.THIRD]: 'Terzo trimestre',
  [QUARTERS.FOURTH]: 'Quarto trimestre',
  [SORTINGS.ASC]: 'Ascending',
  [SORTINGS.DESC]: 'Descending',
  [STATUS.DONE]: 'Done',
  [STATUS.PENDING]: 'Pending',
  [USER.STATUS.NOT_VERIFIED]: 'Todo',
  [USER.STATUS.RESOLVED]: 'Approved',
  [USER.STATUS.REJECTED]: 'Rejected',
  [USER.RISK.HIGH]: 'High',
  [USER.RISK.LOW]: 'Low',
  [USER.RISK.MODERATED]: 'Moderated',
  [USER.RISK.SEVERE]: 'Severe',
  [USER.RISK.UNCLASSIFIED]: 'Unclassified',
});

export default Object.freeze({
  ICONS,
  LABELS,
  LESSON,
  LEVEL,
  OPERATOR,
  QUARTERS,
  SCENARIO,
  STATUS,
  USER,
});
