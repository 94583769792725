<template>
  <v-card flat>
    <v-card-text>
      <v-form @submit.prevent="onSearch">
        <v-text-field
          dense
          outlined
          rounded
          placeholder="search"
          v-model.trim="query"
        >
          <template #prepend>
            <the-search-bar-filter :sorts="sorts" :onSorting="onSorting" />
          </template>
          <template #prepend-inner>
            <v-icon>{{ mdiMagnify }}</v-icon>
          </template>
          <template #append>
            <v-btn icon @click="onReset">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </template>
          <template #append-outer>
            <the-search-bar-eye
              :positions="positions"
              :onPositioning="onPositioning"
            />
          </template>
        </v-text-field>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import TheSearchBarEye from './TheSearchBarEye';
import TheSearchBarFilter from './TheSearchBarFilter';

import { mdiClose, mdiMagnify } from '@mdi/js';

export default {
  name: 'TheSearchBar',
  components: {
    TheSearchBarEye,
    TheSearchBarFilter,
  },
  props: {
    positions: {
      type: Array,
      note: 'List of available positions',
    },
    sorts: {
      type: Array,
      note: 'List of available sorts',
    },
  },
  emits: ['positioning-view', 'searching', 'sorting-list'],
  data() {
    return {
      mdiClose,
      mdiMagnify,
      query: '',
    };
  },
  methods: {
    onPositioning(value) {
      this.$emit('positioning-view', value);
    },
    onReset() {
      this.query = '';
      this.$emit('searching', this.query);
    },
    onSearch() {
      this.$emit('searching', this.query);
    },
    onSorting(value) {
      this.$emit('sorting-list', value);
    },
  },
};
</script>
