import { AUTH } from '@/constants';
import store from '@/store';

const navigationGuard = {
  async setupAuthorization() {
    if (!store.getters['auth/isAuthenticated']) {
      await store.dispatch('auth/initAuth');
    }
    return store.getters['auth/isAuthenticated']
      ? AUTH.USER_ROLE.AUTHORIZED
      : AUTH.USER_ROLE.NOT_AUTHORIZED;
  },
};

export default navigationGuard;
