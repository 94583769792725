import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const ArchiveDashboardView = loadView('archive/ArchiveDashboardView');

const archiveRoute = {
  path: ROUTE.PATH.ARCHIVE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.ARCHIVE,
      component: ArchiveDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.ARCHIVE_DASHBOARD,
      name: ROUTE.NAME.ARCHIVE_DASHBOARD,
      component: ArchiveDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default archiveRoute;
