import { riskApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} level
   */
  async initRiskAssign({ commit, dispatch }, level = '') {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchIndicators', level)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} level
   */
  async fetchIndicators({ commit }, level = '') {
    try {
      const response = await riskApi.requestList(level);
      commit('addIndicators', response.data.indicators);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} level
   */
  async incrementIndicators({ commit, dispatch }, level = '') {
    commit('setAdding', true);
    await dispatch('fetchIndicators', level);
    commit('setAdding', false);
  },
};
