import { faker } from '@faker-js/faker';

const mockSarAnomalyItems = () => {
  const items = [
    {
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      id: faker.string.uuid(),
      rank: 1,
      title: 'Evasive Conduct with Unorthodox Transactions',
      description:
        'The person to whom the operation is referred refuses to or is repeatedly reluctant to provide routinely requested information or data and intends to carry out operations that are unusual, illogical, or inconsistent in their characteristics or amounts.',
    },
    {
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      id: faker.string.uuid(),
      rank: 2,
      title: 'Dubious Documentation Amidst Atypical Operations',
      description:
        'The person to whom the operation is referred provides information or documents that appear to be untrue or that, even after reminders, are wholly deficient, or inconsistent with each other or with the operation requested or performed, and intends to carry out operations that, in terms of their characteristics or amounts, are unusual, illogical or inconsistent.',
    },
    {
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      id: faker.string.uuid(),
      rank: 3,
      title: 'Aberrant Behavior and Incongruent Transactions',
      description:
        'The person to whom the operation is referred engages in behavior that is wholly different from that commonly engaged in similar cases and intends to carry out operations that are unusual, illogical, or inconsistent in terms of their characteristics or amounts.',
    },
    {
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      id: faker.string.uuid(),
      rank: 4,
      title: 'Change of User Status',
      description:
        'The person to whom the operation is referred has changed anything that is considered a risk factor , such as residence , Job or any other status.',
    },
  ];
  return items;
};

const mockSarItem = () => {
  const item = {
    address:
      faker.location.streetAddress({ useFullAddress: true }) +
      ' - ' +
      faker.location.city(),
    cellphone: faker.phone.number(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    email: faker.internet.email(),
    id: faker.string.uuid(),
    job: faker.person.jobTitle(),
    name: faker.person.fullName(),
    phone: faker.phone.number(),
  };
  return item;
};

const mockSarItems = (items = 3) => {
  return faker.helpers.multiple(mockSarItem, {
    count: items,
  });
};

const mockSarSummary = () => {
  const item = {
    all: faker.number.int({ max: 5000, min: 1000 }),
    done: faker.number.int({ max: 10, min: 1 }),
    today: faker.number.int({ max: 10, min: 1 }),
    todo: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

export { mockSarAnomalyItems, mockSarItem, mockSarItems, mockSarSummary };
