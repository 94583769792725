<template>
  <v-row class="py-3 align-end">
    <v-col cols="12" sm="4" md="2">
      <slot name="box-1" />
    </v-col>
    <v-col cols="12" sm="4" md="2">
      <slot name="box-2" />
    </v-col>
    <v-col cols="12" sm="4" md="2">
      <slot name="box-3" />
    </v-col>
    <v-col cols="12" sm="4" md="2">
      <slot name="box-4" />
    </v-col>
    <v-col cols="12" sm="4" md="2">
      <slot name="box-5" />
    </v-col>
    <v-col cols="12" sm="4" md="2" class="d-flex flex-column">
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheDashboardHeader',
};
</script>
