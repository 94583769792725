<template>
  <v-menu open-on-hover offset-y style="max-width: 600px">
    <template #activator="{ on, attrs }">
      <v-btn icon :disabled="!sorts.length" v-bind="attrs" v-on="on">
        <v-icon>{{ mdiFilter }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(sort, index) in sorts"
        :key="index"
        @click="onSorting(sort)"
      >
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            <span v-text="sort" />
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>{{ LISTINGS.ICONS[sort] }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiFilter } from '@mdi/js';
import { LISTINGS } from '@/constants';

export default {
  name: 'TheSearchBarFilter',
  props: {
    sorts: {
      type: Array,
      default: () => [],
    },
    onSorting: {
      type: Function,
      required: true,
      note: 'Callback',
    },
  },
  data() {
    return {
      LISTINGS,
      mdiFilter,
    };
  },
};
</script>
