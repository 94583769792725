import { mockTxsScenarioItems, mockTxsScenarioSummary } from '@/__mocks__';

const txsScenarioApi = {
  requestList() {
    return new Promise((resolve) => {
      const response = {
        data: {
          scenarios: mockTxsScenarioItems(),
        },
      };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
  requestSummary() {
    return new Promise((resolve) => {
      const response = { data: mockTxsScenarioSummary() };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
};

export default txsScenarioApi;
