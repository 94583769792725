<template>
  <v-menu open-on-hover offset-y style="max-width: 600px">
    <template #activator="{ on, attrs }">
      <v-btn icon :disabled="!positions.length" v-bind="attrs" v-on="on">
        <v-icon>{{ mdiEye }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(position, index) in positions"
        :key="index"
        @click="onPositioning(position)"
      >
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            <span v-text="position" />
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>{{ LISTINGS.ICONS[position] }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiEye } from '@mdi/js';
import { LISTINGS } from '@/constants';

export default {
  name: 'TheSearchBarEye',
  props: {
    positions: {
      type: Array,
      default: () => [],
    },
    onPositioning: {
      type: Function,
      required: true,
      note: 'Callback',
    },
  },
  data() {
    return {
      LISTINGS,
      mdiEye,
    };
  },
};
</script>
