<template>
  <v-select
    dense
    outlined
    item-text="text"
    item-value="value"
    :disabled="disabled"
    :items="items"
    v-model="selected"
  >
    <template #label>
      <span class="info--text" v-text="label" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'BaseFormSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
