export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAdding: (state) => state.isAdding,
  summary: (state) => state.summary,
  list: (state) => state.list,
  paging: (state) => state.paging,
  detail: (state) => state.detail,
  cursor: (state) =>
    state.paging && state.paging.next_cursor ? state.paging.next_cursor : null,
};
