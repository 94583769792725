import { mockRiskIndicatorItems } from '@/__mocks__';

const riskApi = {
  requestList(level) {
    return new Promise((resolve) => {
      const items = 10;
      const response = {
        data: {
          indicators: mockRiskIndicatorItems({ items, level }),
          paging: {
            next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            next_link:
              'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
            total_count: items,
          },
        },
      };
      setTimeout(() => {
        resolve(response);
      }, 100);
    });
  },
};

export default riskApi;
