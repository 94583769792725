import Vue from 'vue';
import VueRouter from 'vue-router';

import { AUTH, ROUTE } from '@/constants';
import navigationGuard from './navigation-guard';
import archiveRoute from './routes/archive-route';
import certifiedTrainingRoute from './routes/certified-training-route';
import dashboardRoute from './routes/dashboard-route';
import dbExportRoute from './routes/db-export-route';
import loginRoute from './routes/login-route';
import notFoundRoute from './routes/not-found-route';
import operatorLogsRoute from './routes/operator-logs-route';
import preScreeningRoute from './routes/pre-screening-route';
import riskAssessmentRoute from './routes/risk-assessment-route';
import sarRoute from './routes/sar-route';
import txsMonitorRoute from './routes/txs-monitor-route';
import vaspRoute from './routes/vasp-route';
import verifiableCredentialsRoute from './routes/verifiable-credentials-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTE.PATH.ROOT,
    redirect: () => ROUTE.PATH.DASHBOARD,
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
    },
  },
];

routes.push(
  archiveRoute,
  certifiedTrainingRoute,
  dashboardRoute,
  dbExportRoute,
  loginRoute,
  operatorLogsRoute,
  preScreeningRoute,
  riskAssessmentRoute,
  sarRoute,
  txsMonitorRoute,
  vaspRoute,
  verifiableCredentialsRoute,
);
// Must be the last route
routes.push(notFoundRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === AUTH.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();
  if (userRole === AUTH.USER_ROLE.NOT_AUTHORIZED) {
    next(ROUTE.PATH.LOGIN);
    return;
  } else {
    next();
    return;
  }
});

export default router;

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
