import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const SarDashboardView = loadView('sar/SarDashboardView');
const SarManageView = loadView('sar/SarManageView');
const SarNewCounterpartyView = loadView('sar/SarNewCounterpartyView');
const SarNewEffectsView = loadView('sar/SarNewEffectsView');
const SarNewRouter = loadView('sar/SarNewRouter');
const SarNewSarView = loadView('sar/SarNewSarView');
const SarNewTypeView = loadView('sar/SarNewTypeView');
const SarNewUserView = loadView('sar/SarNewUserView');

const sarRoute = {
  path: ROUTE.PATH.SAR,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.SAR,
      component: SarDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.SAR_DASHBOARD,
      name: ROUTE.NAME.SAR_DASHBOARD,
      component: SarDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.SAR_MANAGE,
      name: ROUTE.NAME.SAR_MANAGE,
      component: SarManageView,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.SAR_NEW,
      component: SarNewRouter,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.SAR_NEW,
          component: SarNewUserView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.SAR_NEW_COUNTERPARTY,
          name: ROUTE.NAME.SAR_NEW_COUNTERPARTY,
          component: SarNewCounterpartyView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.SAR_NEW_EFFECTS,
          name: ROUTE.NAME.SAR_NEW_EFFECTS,
          component: SarNewEffectsView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.SAR_NEW_SAR,
          name: ROUTE.NAME.SAR_NEW_SAR,
          component: SarNewSarView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.SAR_NEW_TYPE,
          name: ROUTE.NAME.SAR_NEW_TYPE,
          component: SarNewTypeView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.SAR_NEW_USER,
          name: ROUTE.NAME.SAR_NEW_USER,
          component: SarNewUserView,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
      ],
    },
  ],
};

export default sarRoute;
