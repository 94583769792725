import { userRegistryApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} status
   */
  async initPreScreening({ commit, dispatch }, status) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchSummary'), dispatch('fetchUsers', status)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchSummary({ commit }) {
    try {
      const response = await userRegistryApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} status
   */
  async fetchUsers({ commit }, status = '') {
    try {
      const response = await userRegistryApi.requestList(status);
      commit('addUsers', response.data.users);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} status
   */
  async incrementUsers({ commit, dispatch }, status) {
    commit('setAdding', true);
    await dispatch('fetchUsers', status);
    commit('setAdding', false);
  },
};
