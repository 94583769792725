import { faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const lessonTypes = Object.values(LISTINGS.LESSON.TYPE);

const mockLessonItem = (counter) => {
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    description: faker.lorem.sentence({ min: 10, max: 20 }),
    id: faker.string.uuid(),
    name: `${counter}. ${faker.lorem.sentence({ min: 3, max: 5 })}`,
    type: faker.helpers.arrayElement(lessonTypes),
  };
  return item;
};

const mockLessonItems = ({ items = 3 } = {}) => {
  let counter = 1;
  return faker.helpers.multiple(() => mockLessonItem(counter++), {
    count: items,
  });
};

const mockCourseItem = (counter) => {
  const item = {
    created_at: faker.date.soon({ refDate: '2024-01-01T12:00:00.000Z' }),
    description: faker.lorem.sentence({ min: 10, max: 20 }),
    id: faker.string.uuid(),
    lessons: mockLessonItems({ items: 10 }),
    name: `${counter}. ${faker.lorem.sentence({ min: 3, max: 5 })}`,
  };
  return item;
};

const mockCourseItems = ({ items = 3 } = {}) => {
  let counter = 1;
  return faker.helpers.multiple(() => mockCourseItem(counter++), {
    count: items,
  });
};

const mockCourseSummary = () => {
  const item = {
    certificate_expiration: faker.number.int({ max: 300, min: 10 }),
    courses: {
      done: faker.number.int({ max: 10, min: 1 }),
      todo: faker.number.int({ max: 10, min: 1 }),
    },
    quizzes: {
      done: faker.number.int({ max: 100, min: 10 }),
      todo: faker.number.int({ max: 100, min: 10 }),
    },
  };
  return item;
};

export {
  mockCourseItem,
  mockCourseItems,
  mockLessonItem,
  mockLessonItems,
  mockCourseSummary,
};
