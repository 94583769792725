import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const VerifiableCredentialsView = loadView(
  'verifiable-credentials/VerifiableCredentialsView',
);

const verifiableCredentialsRoute = {
  path: ROUTE.PATH.VERIFIABLE_CREDENTIALS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.VERIFIABLE_CREDENTIALS,
      component: VerifiableCredentialsView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default verifiableCredentialsRoute;
