import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const DbExportView = loadView('db-export/DbExportView');

const dbExportRoute = {
  path: ROUTE.PATH.DB_EXPORT,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.DB_EXPORT,
      component: DbExportView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default dbExportRoute;
